<template>
  <Layout>
    <fleet-header :id="$route.params.id" />
    <div v-if="!$apollo.queries.bookings.loading">
      <b-card body-class="p-0">
        <b-card-header>
          <b-row>
            <b-col cols="8">
              <h4 class="mt-2">
                Histórico de Locações
              </h4>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body body-class="p-0">
          <b-table
            responsive
            striped
            hover
            :items="bookings.items"
            :fields="fields"
            @row-clicked="goTo"
          >
            <template v-slot:cell(driver)="data">
              <strong>{{ data.item.driver.name }}</strong>
            </template>
            <template
              v-slot:cell(started_at)="data"
            >
              {{ data.item.started_at | moment('DD/MM/YYYY') }}
            </template>
            <template v-slot:cell(finished_at)="data">
              <span v-if="data.item.finished_at">
                {{ data.item.finished_at | moment('DD/MM/YYYY') }}
              </span>
              <span v-else>-</span>
            </template>
            <template v-slot:cell(duration)="data">
              {{ getDuration(data.item) }} dias
            </template>
            <template v-slot:cell(status)="data">
              <b-badge
                :class="`set-${data.item.status}`"
              >
                {{ data.item.status }}
              </b-badge>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="bookings.errorType || bookings.items.length === 0" class="p-0">
          <content-controll :service="bookings" />
        </b-card-body>
      </b-card>
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import FleetHeader from '@components/fleet/header';

import FLEET_BOOKINGS from '@graphql/booking/queries/fleet.gql';

export default {
  name: 'FleetBookings',
  page: {
    title: 'Locações',
  },
  components: {
    Layout,
    FleetHeader,
    ContentControll,
    ContentLoading,
  },
  data: () => {
    return {
      fields: [
        {
          key: 'driver',
          label: 'Motorista',
        },
        {
          key: 'started_at',
          label: 'Data início',
          sortable: true,
        },
        {
          key: 'finished_at',
          label: 'Data fim',
          sortable: true,
        },
        {
          key: 'duration',
          label: 'Duração',
        },
        {
          key: 'status',
          label: '',
          sortable: false,
          class: 'text-right',
        },
      ],
    };
  },
  apollo: {
    bookings: {
      query: FLEET_BOOKINGS,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update: data => data.bookingsByCar,
    },
  },
  methods: {
    goTo(item) {
      this.$router.push({
        path: `/drivers/profile/${item.driver.id}`,
      });
    },
    getDuration(item) {
      if (!item) return '';
      let start = this.$moment.utc(item.started_at);
      let end_count = item.finished_at
        ? this.$moment.utc(item.finished_at)
        : this.$moment.utc(new Date());
      return end_count.diff(start, 'days');
    },
  },
};
</script>

<style lang="sass" scoped>
.badge
  color: #FFFFFF
  padding: 5px 10px
  &.set
    &-ACTIVE
      background-color: green
    &-CLOSED,
    &-CANCELED
      background-color: red
    &-PENDING,
    &-PAUSED,
    &-EXPIRED
      background-color: orange
</style>
