var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("fleet-header", { attrs: { id: _vm.$route.params.id } }),
      !_vm.$apollo.queries.bookings.loading
        ? _c(
            "div",
            [
              _c(
                "b-card",
                { attrs: { "body-class": "p-0" } },
                [
                  _c(
                    "b-card-header",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "8" } }, [
                            _c("h4", { staticClass: "mt-2" }, [
                              _vm._v(" Histórico de Locações "),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card-body",
                    { attrs: { "body-class": "p-0" } },
                    [
                      _c("b-table", {
                        attrs: {
                          responsive: "",
                          striped: "",
                          hover: "",
                          items: _vm.bookings.items,
                          fields: _vm.fields,
                        },
                        on: { "row-clicked": _vm.goTo },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(driver)",
                              fn: function (data) {
                                return [
                                  _c("strong", [
                                    _vm._v(_vm._s(data.item.driver.name)),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "cell(started_at)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          data.item.started_at,
                                          "DD/MM/YYYY"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(finished_at)",
                              fn: function (data) {
                                return [
                                  data.item.finished_at
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("moment")(
                                                data.item.finished_at,
                                                "DD/MM/YYYY"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("span", [_vm._v("-")]),
                                ]
                              },
                            },
                            {
                              key: "cell(duration)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getDuration(data.item)) +
                                      " dias "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(status)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "b-badge",
                                    { class: `set-${data.item.status}` },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.status) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3042353766
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm.bookings.errorType || _vm.bookings.items.length === 0
                    ? _c(
                        "b-card-body",
                        { staticClass: "p-0" },
                        [
                          _c("content-controll", {
                            attrs: { service: _vm.bookings },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "animated fadeIn" },
            [_c("content-loading")],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }